import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit, output } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { InsuranceFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptionSelectionOverlaySearchComponent } from "@cg/olb/shared";
import { DrawerComponent, OptionSelectionItem, OverlayService } from "@cg/shared";

@Component({
  selector: "cgx-insurance-overlay",
  standalone: true,
  imports: [DrawerComponent, OptionSelectionOverlaySearchComponent, AsyncPipe, TranslocoPipe],
  templateUrl: "./insurance-overlay.component.html"
})
export class InsuranceOverlayComponent implements OnInit {
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly overlayService = inject(OverlayService);

  public insurances = toSignal(this.insuranceFacade.createInsurancesOptionsWithTopInsurances());

  public itemSelected = output<OptionSelectionItem>();

  public ngOnInit(): void {
    this.insuranceFacade.loadInsurances();
  }

  public close = (item?: OptionSelectionItem): void => {
    if (item) {
      this.itemSelected.emit(item);
    }
    this.overlayService.close();
  };
}
