<div class="flex w-full flex-col gap-6">
  @if (content().headline) {
    <cgx-headline [content]="content().headline"></cgx-headline>
  }
  <div class="flex w-full flex-col gap-6 cgxT:flex-row cgxT:items-center">
    <div class="flex w-full grow flex-col justify-between gap-6">
      <cgx-picture class="mx-auto" [content]="content().picture"></cgx-picture>
      @if (content().cta) {
        <cgx-cta [content]="content().cta"></cgx-cta>
      }
    </div>
    <cgx-contact-widget class="grow [--width:100%]" [content]="content().items"></cgx-contact-widget>
  </div>
</div>
