<div
  class="flex flex-col cgxD:flex-row cgxD:gap-12"
  [ngClass]="{
    'gap-6 cgxT:gap-8': everyItemContainsHeadline(content()),
    'gap-4': !everyItemContainsHeadline(content())
  }"
>
  @for (item of content().items; track item.text) {
    <div class="flex-1" [class.max-w-[701px]]="isOneItem(content())">
      @if (item.title) {
        <cgx-headline
          class="block"
          [ngClass]="{
            'mb-4': isOneItem(content()),
            'mb-2 cgxT:mb-4': !isOneItem(content())
          }"
          [content]="item.headline"
        ></cgx-headline>
      } @else if (containsSomeHeadline(content())) {
        <!-- add headline placeholder for spacing -->
        <div
          [ngClass]="{
            'cgxD:mb-4 cgxD:h-10': isOneItem(content()),
            'cgxD:mb-4 cgxD:h-8': !isOneItem(content())
          }"
        ></div>
      }
      <cgx-paragraph [content]="item.paragraph"></cgx-paragraph>
    </div>
  }
</div>
