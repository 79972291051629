import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { HeadlineComponent, IconComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import { CgxHeadline, CgxParagraph, CgxTrustNumberItem, CgxTrustNumbers } from "@cg/content-api/typescript-interfaces";
import { SafeContentPipe } from "@cg/shared";

@Component({
  selector: "cgx-trust-numbers",
  templateUrl: "./trust-numbers.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeContentPipe, NgClass, IconComponent, ParagraphComponent, HeadlineComponent]
})
export class TrustNumbersComponent {
  public content = input.required<
    { headline: CgxHeadline } & { items: { headline: CgxHeadline; paragraph: CgxParagraph }[] },
    CgxTrustNumbers
  >({
    transform: (value: CgxTrustNumbers) => ({
      headline: {
        title: value.title,
        type: "h2",
        ngTemplate: "cgxHeadline"
      },
      items: value.items.map((item: CgxTrustNumberItem) => ({
        headline: {
          title: item.title,
          type: "h1",
          ngTemplate: "cgxHeadline"
        },
        paragraph: {
          alignment: "left",
          condensed: false,
          size: "s",
          text: item.subtitle,
          ngTemplate: "cgxParagraph"
        }
      }))
    })
  });
}
