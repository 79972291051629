<div class="flex flex-col gap-2 cgxT:gap-8 cgxD:gap-12">
  <cgx-headline [content]="content().headline"></cgx-headline>
  @if (consent()) {
    <div
      class="trustpilot-widget"
      data-locale="de-DE"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="554720c10000ff00057f2b9b"
      [attr.data-style-height]="widgetHeight()"
      data-style-width="100%"
      data-theme="light"
      data-stars="3,4,5"
      data-review-languages="de"
      data-font-family="Roboto"
    ></div>
  } @else {
    <div class="mx-auto flex flex-col items-center gap-12 cgxT:gap-8 cgxD:flex-row cgxD:gap-12">
      <cgx-picture [content]="picture"></cgx-picture>
      <div class="flex flex-col items-center justify-center gap-4 cgxD:items-start">
        <cgx-headline
          [content]="{ title: 'cgx.trustpilot.title' | transloco, type: 'h4', alignment: 'center' }"
        ></cgx-headline>
        <cgx-cta class="part-[link]:x-[text-base,text-cg-anthracite]" [content]="link"></cgx-cta>
      </div>
    </div>
  }
</div>
