import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { IconComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import { SafeContentPipe } from "@cg/shared";
import type { CgxUsps } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-usps",
  templateUrl: "./usps.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeContentPipe, NgClass, IconComponent, ParagraphComponent]
})
export class UspsComponent {
  public content = input<CgxUsps>();
}
