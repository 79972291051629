import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, input, signal } from "@angular/core";
import { HeadlineComponent, IconComponent, InfoBtnComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import type { CgxHeadline, CgxMsg, CgxParagraph } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-msg",
  templateUrl: "./msg.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, HeadlineComponent, IconComponent, ParagraphComponent, InfoBtnComponent]
})
export class MsgComponent {
  public content = input.required<CgxMsg & { paragraph?: CgxParagraph; headline?: CgxHeadline }, CgxMsg>({
    transform: (value: CgxMsg) => ({
      ...value,
      paragraph: {
        alignment: "left",
        condensed: false,
        size: "m",
        text: value.text,
        ngTemplate: "cgxParagraph"
      },
      headline: value.title
        ? {
            type: "h4",
            title: value.title,
            ngTemplate: "cgxHeadline"
          }
        : undefined
    })
  });

  public expanded = signal(false);
  public showFullText = computed(() => !this.content().showMoreButton || this.expanded());

  public expand() {
    this.expanded.set(true);
  }
}
