import { NgClass, NgTemplateOutlet, SlicePipe } from "@angular/common";
import { Component, input, signal, TemplateRef } from "@angular/core";
import { ExpansionPanelComponent, IconComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { GenericOpeningHours } from "@cg/core/interfaces";
import { accordionChevronDownIcon } from "@cg/icon";

@Component({
  selector: "cgx-opening-hours",
  templateUrl: "./opening-hours.component.html",
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    TranslocoPipe,
    ExpansionPanelComponent,
    SlicePipe,
    ParagraphComponent,
    IconComponent
  ]
})
export class OpeningHoursComponent {
  public openingHoursList = input<GenericOpeningHours[], GenericOpeningHours[]>([], {
    transform: (value: GenericOpeningHours[]) => {
      if (!this.reorderOpeningHours()) {
        return value;
      }

      const currentDayIndex = value.findIndex(({ currentDay }: GenericOpeningHours) => currentDay);

      if (currentDayIndex === -1) {
        return value;
      }

      return [...value.slice(currentDayIndex), ...value.slice(0, currentDayIndex)];
    }
  });
  public titleTemplateRef = input<TemplateRef<unknown>>();
  public reorderOpeningHours = input<boolean>(true);

  public readonly accordionIcon = accordionChevronDownIcon;
  public expanded = signal(false);

  public accordionToggled(expanded: boolean): void {
    this.expanded.set(expanded);
  }
}
