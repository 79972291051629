<div
  class="flex h-full grid-rows-subgrid flex-col gap-4 overflow-hidden rounded-xl bg-cg-gray-light-80 px-4 pb-4"
  cgxOpenLink
  [link]="content().cta ? null : content().link"
>
  <div
    cgxResponsiveBg
    [src]="content().picture"
    cgxOpenLink
    [link]="content().link"
    class="-mx-4 h-[160px] min-h-[160px] bg-cover bg-center"
  ></div>

  <div class="flex flex-col gap-2">
    @if (content().title) {
      <cgx-headline [content]="content().headline"></cgx-headline>
    }

    @if (content().paragraph) {
      <cgx-paragraph [content]="content().paragraph"></cgx-paragraph>
    }
  </div>

  @if (content().cta) {
    <cgx-cta class="mt-auto" [content]="content().cta"></cgx-cta>
  }
</div>
