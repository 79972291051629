import { ChangeDetectionStrategy, Component, computed, effect, inject, input, OnInit } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { take } from "rxjs/operators";
import { CtaComponent, HeadlineComponent, PictureComponent } from "@cg/cgx-ui/core";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { CgxCta, CgxHeadline, CgxPicture, CgxTrustpilot } from "@cg/content-api/typescript-interfaces";
import { TrustpilotService } from "@cg/customerrating";
import { environment } from "@cg/environments";
import { BreakpointService } from "@cg/shared";

@Component({
  selector: "cgx-trustpilot",
  templateUrl: "./trustpilot.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, TranslocoPipe, PictureComponent, CtaComponent]
})
export class TrustpilotComponent implements OnInit {
  private readonly trustpilotService = inject(TrustpilotService);
  private readonly breakpointService = inject(BreakpointService);
  private readonly translocoService = inject(TranslocoService);

  public consent = this.trustpilotService.initialized;
  private isMobile = toSignal(this.breakpointService.isTouch$);

  public widgetHeight = computed(() => (this.isMobile() ? "334px" : "201px"));

  public content = input.required<{ headline: CgxHeadline }, CgxTrustpilot>({
    transform: (value: CgxTrustpilot) => ({
      headline: {
        title: value.title,
        alignment: "center",
        type: "h2",
        ngTemplate: "cgxHeadline"
      }
    })
  });

  public readonly picture: CgxPicture = {
    sizes: [],
    source: `${environment.assetPath}/cgx/trustpilot.svg`,
    ngTemplate: "cgxPicture"
  };

  public link: CgxCta;

  public constructor() {
    effect(() => {
      if (this.consent()) {
        this.trustpilotService.reinitialize();
      }
    });
  }

  public ngOnInit(): void {
    this.translocoService
      .selectTranslate("cgx.trustpilot.cta")
      .pipe(take(1))
      .subscribe(
        (translation: string) =>
          (this.link = {
            id: "link",
            title: translation,
            link: {
              href: "https://de.trustpilot.com/review/carglass.de",
              text: "customerRating.cta",
              target: "_blank"
            },
            variation: "secondary",
            iconDirection: "none",
            ngTemplate: "cgxCta"
          })
      );
  }
}
