<div class="hidden w-full items-center divide-x divide-cg-anthracite-light-80 cgxD:flex">
  @for (item of content().items; track item.title) {
    <a class="flex-1 cursor-pointer" [href]="item.link.href" [target]="item.link.target ?? '_blank'">
      <div class="flex items-center gap-4 px-[90px] pb-5 pt-6">
        <cgx-icon class="[--icon-size:48px]" [content]="item.icon"></cgx-icon>
        <div>
          <cgx-headline [content]="item.headline"></cgx-headline>
          <cgx-paragraph size="s" [content]="item.paragraph"></cgx-paragraph>
        </div>
      </div>
    </a>
  }
</div>
