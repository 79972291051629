<div class="flex flex-col items-center justify-around gap-6 py-12 cgxT:gap-8 cgxD:gap-12 cgxD:py-15">
  <cgx-headline class="mx-auto" [content]="content().headline"></cgx-headline>
  <div class="flex w-full flex-col items-stretch justify-around gap-4 cgxT:flex-row cgxD:gap-6">
    @for (item of content().items; track item.headline) {
      <div class="flex flex-1 flex-col gap-2 rounded-drawer bg-cg-gray-light-80 px-6 py-6 text-center cgxT:py-8">
        <cgx-headline class="mx-auto [&>h1]:text-2xl cgxT:[&>h1]:text-5xl" [content]="item.headline"></cgx-headline>
        <cgx-paragraph [content]="item.paragraph"></cgx-paragraph>
      </div>
    }
  </div>
</div>
