import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { IconComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import type { CgxContactWidget, CgxContactWidgetItem, CgxParagraph } from "@cg/content-api/typescript-interfaces";
import { CccOpeningHoursComponent } from "../ccc-opening-hours/ccc-opening-hours.component";

@Component({
  selector: "cgx-contact-widget",
  templateUrl: "./contact-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, ParagraphComponent, CccOpeningHoursComponent]
})
export class ContactWidgetComponent {
  public content = input.required<
    CgxContactWidget & { items: (CgxContactWidgetItem & { paragraph?: CgxParagraph })[] },
    CgxContactWidget
  >({
    transform: (value: CgxContactWidget) => ({
      ...value,
      items: value.items.map((item: CgxContactWidgetItem) => ({
        ...item,
        paragraph: {
          alignment: "left",
          condensed: false,
          size: "m",
          text: item.text,
          ngTemplate: "cgxParagraph"
        }
      }))
    })
  });
}
