<ng-template #titleTemplate>
  <div class="flex items-start justify-items-start gap-2 pb-1" id="titleTemplate">
    <div class="flex items-center gap-2">
      <ng-container *ngTemplateOutlet="titleTemplateRef()"></ng-container>

      @if (openingHoursList().length) {
        <cgx-icon
          class="!flex transform transition-transform duration-700 [--icon-size:12px]"
          [content]="accordionIcon"
          [ngClass]="{ 'rotate-180 transform': expanded() }"
        ></cgx-icon>
      }
    </div>
  </div>
</ng-template>

<ng-template #contentTemplateDesktop>
  <div class="grid grid-flow-row grid-cols-[min-content_auto] items-center gap-x-4 gap-y-2 cgxT:gap-x-6">
    @for (openingHour of openingHoursList(); track openingHour) {
      <cgx-paragraph
        [ngClass]="{
          '[&>p]:text-cg-anthracite-light-70': !openingHour.dayOpen,
          'font-bold': openingHour.currentDay
        }"
        size="m"
        >{{ openingHour.day | slice: 0 : 2 }}
      </cgx-paragraph>
      @if (openingHour.dayOpen) {
        <div class="flex flex-wrap gap-x-2">
          <cgx-paragraph
            [ngClass]="{
              'font-bold': openingHour.currentDay
            }"
            [content]="{
              text: 'openingHours.fromTo' | transloco: { from: openingHour.from, to: openingHour.to },
              size: 'm',
              ngTemplate: 'cgParagraph'
            }"
          ></cgx-paragraph>
          @if (openingHour.from2) {
            <cgx-paragraph
              [ngClass]="{
                'font-bold': openingHour.currentDay
              }"
              [content]="{
                text: 'openingHours.fromTo' | transloco: { from: openingHour.from2, to: openingHour.to2 },
                size: 'm',
                ngTemplate: 'cgParagraph'
              }"
            ></cgx-paragraph>
          }
        </div>
      } @else {
        <cgx-paragraph
          [ngClass]="{
            'font-bold': openingHour.currentDay
          }"
          class="part-[text]:text-cg-anthracite-light-70"
          size="m"
          >{{ "openingHours.closed" | transloco }}</cgx-paragraph
        >
      }
    }
  </div>
</ng-template>

@if (titleTemplateRef()) {
  <cgx-expansion-panel
    data-pw-mask
    class="!inline m:min-w-[240px]"
    [titleTemplate]="titleTemplate"
    [contentTemplate]="contentTemplateDesktop"
    [expanded]="expanded()"
    maxHeight="220px"
    (expandedChanged)="accordionToggled($event)"
  ></cgx-expansion-panel>
}
