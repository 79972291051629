import { NgClass, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import {
  CtaComponent,
  HeadlineComponent,
  IconComponent,
  ParagraphComponent,
  PictureComponent,
  ResponsiveBgDirective
} from "@cg/cgx-ui/core";
import { SafeContentPipe } from "@cg/shared";
import type { CgxCta, CgxHeadline, CgxParagraph, CgxStageSub } from "@cg/content-api/typescript-interfaces";
import { UspsComponent } from "../usps/usps.component";

@Component({
  selector: "cgx-stage-sub",
  templateUrl: "./stage-sub.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    SafeContentPipe,
    HeadlineComponent,
    IconComponent,
    UspsComponent,
    CtaComponent,
    PictureComponent,
    ResponsiveBgDirective,
    NgTemplateOutlet,
    ParagraphComponent
  ]
})
export class StageSubComponent {
  public content = input.required<
    CgxStageSub & { headline: CgxHeadline; cta?: CgxCta; paragraph?: CgxParagraph },
    CgxStageSub
  >({
    transform: (value: CgxStageSub) => ({
      ...value,
      headline: {
        title: value.title,
        type: "h1",
        ngTemplate: "cgxHeadline"
      },
      cta: value.link
        ? {
            id: "cta",
            link: value.link,
            title: value.link.text,
            iconDirection: "none",
            variation: "primary",
            ngTemplate: "cgxCta"
          }
        : undefined,
      paragraph: value.subtitle
        ? {
            alignment: "left",
            condensed: false,
            size: "m",
            text: value.subtitle,
            ngTemplate: "cgxParagraph"
          }
        : undefined
    })
  });
}
