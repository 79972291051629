import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { CtaComponent, HeadlineComponent, IconComponent, ParagraphComponent, YoutubeComponent } from "@cg/cgx-ui/core";
import { CgxContentBlockVideo, CgxHeadline, CgxParagraph } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-content-block-video",
  templateUrl: "./content-block-video.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, CtaComponent, ParagraphComponent, IconComponent, YoutubeComponent]
})
export class ContentBlockVideoComponent {
  public content = input.required<
    CgxContentBlockVideo & {
      headline: CgxHeadline;
      paragraph: CgxParagraph;
    },
    CgxContentBlockVideo
  >({
    transform: (value: CgxContentBlockVideo) => ({
      ...value,
      headline: value.title
        ? {
            title: value.title,
            type: "h3",
            ngTemplate: "cgxHeadline"
          }
        : null,
      paragraph: value.text
        ? {
            alignment: "left",
            condensed: false,
            size: "l",
            text: value.text,
            ngTemplate: "cgxParagraph"
          }
        : undefined,
      ngTemplate: "cgxContentBlockVideo"
    })
  });
}
