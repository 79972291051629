import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { CtaComponent, HeadlineComponent, IconComponent, ParagraphComponent, PictureComponent } from "@cg/cgx-ui/core";
import { CgxHeadline, CgxLocationTeaser } from "@cg/content-api/typescript-interfaces";
import { CccPhoneInfoComponent } from "@cg/shared";
import { CccOpeningHoursComponent } from "../ccc-opening-hours/ccc-opening-hours.component";
import { ContactWidgetComponent } from "../contact-widget/contact-widget.component";

@Component({
  selector: "cgx-location-teaser",
  templateUrl: "./location-teaser.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    ParagraphComponent,
    CccOpeningHoursComponent,
    CccPhoneInfoComponent,
    HeadlineComponent,
    CtaComponent,
    PictureComponent,
    ContactWidgetComponent
  ]
})
export class LocationTeaserComponent {
  public content = input.required<CgxLocationTeaser & { headline?: CgxHeadline }, CgxLocationTeaser>({
    transform: (value: CgxLocationTeaser) => ({
      ...value,
      headline: value.title
        ? {
            title: value.title,
            type: "h2",
            alignment: "center",
            ngTemplate: "cgxHeadline"
          }
        : undefined,
      cta: value.cta
        ? {
            ...value.cta,
            alignment: "center"
          }
        : undefined
    })
  });
}
