import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { HeadlineComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import {
  CgxContentBlockText,
  CgxContentBlockTextItem,
  CgxHeadline,
  CgxParagraph
} from "@cg/content-api/typescript-interfaces";
import { SafeContentPipe } from "@cg/shared";

@Component({
  selector: "cgx-content-block-text",
  templateUrl: "./content-block-text.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SafeContentPipe, ParagraphComponent, HeadlineComponent]
})
export class ContentBlockTextComponent {
  public content = input.required<
    CgxContentBlockText & { items: (CgxContentBlockTextItem & { headline: CgxHeadline; paragraph: CgxParagraph })[] },
    CgxContentBlockText
  >({
    transform: (value: CgxContentBlockText) => ({
      items: value.items.map((item: CgxContentBlockTextItem) => ({
        ...item,
        headline: item.title
          ? {
              title: item.title,
              type: this.isOneItem(value) ? "h3" : "h4",
              ngTemplate: "cgxHeadline"
            }
          : null,
        paragraph: {
          alignment: "left",
          condensed: false,
          size: "l",
          text: item.text,
          ngTemplate: "cgxParagraph"
        }
      })),
      ngTemplate: "cgxContentBlockText"
    })
  });

  public isOneItem(value: CgxContentBlockText): boolean {
    return value.items.length === 1;
  }

  public containsSomeHeadline(value: CgxContentBlockText): boolean {
    return value.items.some((item: CgxContentBlockTextItem) => item.title);
  }

  public everyItemContainsHeadline(value: CgxContentBlockText): boolean {
    return value.items.every((item: CgxContentBlockTextItem) => item.title);
  }
}
