<div class="flex flex-col gap-4 py-12 cgxD:py-15">
  @if (content().title) {
    <cgx-headline class="mx-auto" [content]="content().headline"></cgx-headline>
  }

  <cgx-slider class="cgxD:[--slider-gap:24px]">
    @for (item of content().items; track item) {
      <cgx-slider-item
        class="min-w-[296px] first:ml-4 last:mr-4 cgxT:min-w-[unset] cgxT:flex-1 cgxT:first:ml-0 cgxT:last:mr-0"
      >
        <cgx-teaser-item [content]="item"></cgx-teaser-item
      ></cgx-slider-item>
    }
  </cgx-slider>
</div>
