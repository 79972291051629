<div class="flex gap-2 rounded-xl bg-[#0C9D6E1A] p-4 cgxT:gap-4">
  <cgx-icon class="cgxT:[--icon-size:48px]" [content]="content().icon"></cgx-icon>
  <div class="flex flex-col gap-2">
    @if (content().headline) {
      <cgx-headline [content]="content().headline"></cgx-headline>
    }

    <cgx-paragraph
      class="overflow-hidden transition-all duration-500 ease-in"
      [ngClass]="{
        'line-clamp-2 max-h-10 cgxT:line-clamp-[unset] cgxT:max-h-fit': !showFullText(),
        'max-h-80': showFullText()
      }"
      [content]="content().paragraph"
    ></cgx-paragraph>
    @if (content().showMoreButton) {
      <cgx-info-btn
        class="h-6 overflow-hidden opacity-100 transition-all duration-500 ease-out cgxT:hidden"
        [ngClass]="{ '!h-0 !opacity-0': expanded() }"
        (click)="expand()"
      ></cgx-info-btn>
    }
  </div>
</div>
