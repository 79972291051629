import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { HeadlineComponent, IconComponent, ParagraphComponent } from "@cg/cgx-ui/core";
import { SafeContentPipe } from "@cg/shared";
import type { CgxHeadline, CgxParagraph, CgxWidgetItem, CgxWidgets } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-widgets",
  templateUrl: "./widgets.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SafeContentPipe, ParagraphComponent, HeadlineComponent, IconComponent]
})
export class WidgetsComponent {
  public content = input.required<
    CgxWidgets & { items: (CgxWidgetItem & { headline: CgxHeadline; paragraph: CgxParagraph })[] },
    CgxWidgets
  >({
    transform: (value: CgxWidgets) => ({
      ...value,
      items: value.items.map((item: CgxWidgetItem) => ({
        ...item,
        headline: {
          title: item.title,
          type: "h4",
          ngTemplate: "cgxHeadline"
        },
        paragraph: {
          alignment: "left",
          condensed: false,
          size: "s",
          text: item.subtitle,
          ngTemplate: "cgxParagraph"
        }
      }))
    })
  });
}
