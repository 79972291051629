import { NgClass, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import {
  CtaComponent,
  HeadlineComponent,
  IconComponent,
  PictureComponent,
  ResponsiveBgDirective
} from "@cg/cgx-ui/core";
import { CustomerratingMinimalComponent } from "@cg/customerrating";
import { SafeContentPipe } from "@cg/shared";
import type { CgxCta, CgxHeadline, CgxStageHome } from "@cg/content-api/typescript-interfaces";
import { UspsComponent } from "../usps/usps.component";

@Component({
  selector: "cgx-stage-home",
  templateUrl: "./stage-home.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    SafeContentPipe,
    HeadlineComponent,
    IconComponent,
    UspsComponent,
    CtaComponent,
    PictureComponent,
    ResponsiveBgDirective,
    NgTemplateOutlet,
    CustomerratingMinimalComponent
  ]
})
export class StageHomeComponent {
  public content = input.required<CgxStageHome & { cta?: CgxCta; headline: CgxHeadline }, CgxStageHome>({
    transform: (value: CgxStageHome) => ({
      ...value,
      headline: {
        title: value.title,
        type: "h1",
        ngTemplate: "cgxHeadline"
      },
      cta: {
        id: "cta",
        link: value.link,
        title: value.link.text,
        iconDirection: "none",
        variation: "primary",
        ngTemplate: "cgxCta"
      },
      usps: {
        ...value.usps,
        variation: "small"
      }
    })
  });
}
