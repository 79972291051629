import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { IconComponent } from "@cg/cgx-ui/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { inputDoneIcon } from "@cg/icon";
import type { CgxDamageTypeTable } from "@cg/content-api/typescript-interfaces";

export interface TableRow {
  text: string;
  repair: boolean;
  replace: boolean;
}

@Component({
  selector: "cgx-damage-type-table",
  templateUrl: "./damage-type-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, IconComponent, NgClass]
})
export class DamageTypeTableComponent {
  // is not used but needed for component factory
  public content = input<CgxDamageTypeTable>();

  public readonly tableContent: TableRow[] = [
    {
      text: "cgx.damageTypeTable.statement.validRepairChipAmount",
      repair: true,
      replace: false
    },
    {
      text: "cgx.damageTypeTable.statement.validRepairChipSize",
      repair: true,
      replace: false
    },
    {
      text: "cgx.damageTypeTable.statement.replaceChipBorderPosition",
      repair: false,
      replace: true
    },
    {
      text: "cgx.damageTypeTable.statement.replaceChipViewPosition",
      repair: false,
      replace: true
    },
    {
      text: "cgx.damageTypeTable.statement.replaceCrack",
      repair: false,
      replace: true
    }
  ];

  public readonly mobileContent = [
    {
      title: "cgx.damageTypeTable.header.repair",
      items: this.tableContent.filter((item: TableRow) => item.repair)
    },
    {
      title: "cgx.damageTypeTable.header.replace",
      items: this.tableContent.filter((item: TableRow) => item.replace)
    }
  ];

  public checkIcon = inputDoneIcon;
}
