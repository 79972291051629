<div
  class="flex flex-col gap-6 cgxT:gap-8 cgxD:gap-12"
  [ngClass]="{
    'cgxD:flex-row-reverse': content().pictureAlignment === 'right',
    'cgxD:flex-row': content().pictureAlignment === 'left'
  }"
>
  <div
    cgxResponsiveBg
    class="h-[120px] w-full rounded-lg bg-cover bg-center cgxT:h-60 cgxD:h-[320px] cgxD:min-w-[488px] cgxD:max-w-[488px]"
    [src]="{ source: content().picture.source, sizes: content().picture.sizes }"
  ></div>
  <div class="flex flex-col gap-2 cgxT:gap-4">
    @if (content().headline) {
      <cgx-headline [content]="content().headline"></cgx-headline>
    }
    <cgx-paragraph [content]="content().paragraph"></cgx-paragraph>

    @if (content().cta) {
      <cgx-cta class="mt-4" [content]="content().cta"></cgx-cta>
    }
  </div>
</div>
