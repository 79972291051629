import { CommonModule } from "@angular/common";
import { Component, effect, inject, input, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatCard, MatCardContent, MatCardHeader } from "@angular/material/card";
import {
  CostCheckInsuranceType,
  CtaComponent,
  HeadlineComponent,
  IconComponent,
  PictureComponent
} from "@cg/cgx-ui/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { ConfigFacade } from "@cg/config";
import { CgxCostcheck } from "@cg/content-api/typescript-interfaces";
import { OpportunityConfig } from "@cg/core/interfaces";
import { FakeDropdownComponent } from "@cg/core/ui";
import { bulletpointIcon, confirmedIcon } from "@cg/icon";
import { OptionSelectionItem, OverlayService, RadioButtonGroup, RadioButtonGroupComponent } from "@cg/shared";
import { OVERLAY_POSITION } from "@cg/core/enums";
import { InsuranceOverlayComponent } from "../insurance-overlay/insurance-overlay.component";

@Component({
  selector: "cgx-cost-check",
  standalone: true,
  imports: [
    CommonModule,
    HeadlineComponent,
    IconComponent,
    TranslocoPipe,
    PictureComponent,
    MatCardContent,
    MatCardHeader,
    MatCard,
    CtaComponent,
    FakeDropdownComponent,
    RadioButtonGroupComponent
  ],
  templateUrl: "./cost-check.component.html"
})
export class CostCheckComponent {
  private readonly overlayService = inject(OverlayService);
  private readonly configFacade = inject(ConfigFacade);

  // is not used but needed for component factory
  public content = input<CgxCostcheck>();

  public opportunityConfig = toSignal(this.configFacade.opportunityConfig$);

  public isKasko = signal<boolean | undefined>(null);
  public selectedInsurance = signal<OptionSelectionItem | undefined>(undefined);

  public repairList: { key: string; value: string }[] = [];
  public replaceList: { key: string; value: string }[] = [];

  public insuranceOptions: RadioButtonGroup = {
    controlName: "costCheck.insurace.title",
    buttons: [
      {
        title: "costCheck.insurance.buttons.yes",
        radio: { id: "insurance-kasko", value: CostCheckInsuranceType.KASKO }
      },
      {
        title: "costCheck.insurance.buttons.no",
        radio: { id: "insurance-not-kasko", value: CostCheckInsuranceType.NOT_KASKO }
      }
    ]
  };

  protected readonly bulletpointIcon = bulletpointIcon;
  protected readonly confirmedIcon = confirmedIcon;

  public constructor() {
    effect(() => {
      if (this.opportunityConfig()) {
        this.initPrices(this.opportunityConfig());
      }
    });
  }

  public onSelectedValueChanged(insuranceType: CostCheckInsuranceType): void {
    this.isKasko.set(insuranceType === CostCheckInsuranceType.KASKO);
  }

  public onInsuranceSelectionClicked(): void {
    const itemSelected = (item: OptionSelectionItem) => this.selectInsurance(item);
    this.overlayService.open(
      InsuranceOverlayComponent,
      {
        itemSelected
      },
      { position: OVERLAY_POSITION.CENTER }
    );
  }

  private initPrices(config: OpportunityConfig): void {
    this.repairList = [
      { key: "costCheck.withoutInsurance.priceList.repair.oneStone", value: config.price_1_stone?.replace(".", ",") },
      { key: "costCheck.withoutInsurance.priceList.repair.twoStones", value: config.price_2_stone?.replace(".", ",") }
    ];

    this.replaceList = [
      {
        key: "costCheck.withoutInsurance.priceList.replace.frontWithoutAssistant",
        value: config.price_front_without_assistant
      },
      {
        key: "costCheck.withoutInsurance.priceList.replace.frontWithAssistant",
        value: config.price_front_with_assistant
      },
      { key: "costCheck.withoutInsurance.priceList.replace.side", value: config.price_side },
      { key: "costCheck.withoutInsurance.priceList.replace.rear", value: config.price_rear },
      { key: "costCheck.withoutInsurance.priceList.replace.emergency", value: config.price_emergency_glass }
    ];
  }

  private selectInsurance(item: OptionSelectionItem): void {
    this.selectedInsurance.set(item);
  }
}
