import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { CtaComponent, HeadlineComponent, ParagraphComponent, ResponsiveBgDirective } from "@cg/cgx-ui/core";
import { CgxContentBlockImage, CgxHeadline, CgxParagraph } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-content-block-image",
  templateUrl: "./content-block-image.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, ParagraphComponent, HeadlineComponent, CtaComponent, ResponsiveBgDirective]
})
export class ContentBlockImageComponent {
  public content = input.required<
    CgxContentBlockImage & { headline: CgxHeadline; paragraph: CgxParagraph },
    CgxContentBlockImage
  >({
    transform: (value: CgxContentBlockImage) => ({
      ...value,
      headline: value.title
        ? {
            title: value.title,
            type: "h4",
            ngTemplate: "cgxHeadline"
          }
        : null,
      paragraph: {
        alignment: "left",
        condensed: false,
        size: "l",
        text: value.text,
        ngTemplate: "cgxParagraph"
      },
      ngTemplate: "cgxContentBlockImage"
    })
  });
}
