<div class="mx-auto max-w-container">
  <div class="flex flex-col justify-between gap-6 cgxD:flex-row">
    <div class="flex grow flex-col">
      <div>
        <cgx-headline
          [content]="{ title: 'costCheck.insurance.type' | transloco, type: 'h4', alignment: 'center' }"
          class="[&>h4]:x-[mb-4]"
        ></cgx-headline>
        <cg-radio-button-group
          class="leading-6 cgxD:[&>div>div]:w-1/2 cgxD:[&>div]:flex-row cgxD:[&>div]:gap-2"
          [content]="insuranceOptions"
          (selectedValueChanged)="onSelectedValueChanged($event)"
        ></cg-radio-button-group>
        @if (isKasko()) {
          <cgx-headline
            [content]="{ title: 'costCheck.insurance.name' | transloco, type: 'h4', alignment: 'center' }"
            class="[&>h4]:x-[pt-6,mb-4]"
          ></cgx-headline>
          <cg-fake-dropdown
            [placeholder]="
              selectedInsurance() ? selectedInsurance().text : ('costCheck.insurance.placeholder' | transloco)
            "
            [hasSelection]="selectedInsurance() !== undefined"
            (press)="onInsuranceSelectionClicked()"
          ></cg-fake-dropdown>
          @if (selectedInsurance()) {
            <div class="mb-4 py-6">
              <div
                class="mb-2 flex flex-row gap-4 rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-green p-4"
              >
                <cgx-icon class="h-5 w-5" [content]="confirmedIcon"></cgx-icon>
                <p class="font-roboto-condensed text-[17px] font-bold leading-5 text-white">
                  {{ "costCheck.withInsurance.title" | transloco }}
                </p>
              </div>
              <div
                class="mb-2 flex flex-row justify-between rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4"
              >
                <div class="flex flex-col">
                  <p
                    class="mb-3 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite"
                    [innerHTML]="'costCheck.withInsurance.repair.title' | transloco"
                  ></p>
                  <p class="mb-1 font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.repair.description" | transloco }}
                  </p>
                </div>
                <div class="flex min-w-[75px] flex-col items-end">
                  <div class="font-roboto-condensed text-[26px] font-bold leading-7 text-cg-green">0 €</div>
                </div>
              </div>
              <div
                class="flex flex-row justify-between rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4"
              >
                <div class="flex flex-col">
                  <p
                    class="mb-3 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite"
                    [innerHTML]="'costCheck.withInsurance.replace.title' | transloco"
                  ></p>
                  <p class="mb-3 font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.replace.subtitle" | transloco }}
                  </p>
                  <p class="mb-1 font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.replace.description" | transloco }}
                  </p>
                </div>
                <div class="flex min-w-[75px] flex-col items-end">
                  <div class="mb-2 font-roboto-condensed text-[26px] font-bold leading-7 text-cg-green">0 €</div>
                  <p class="font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.replace.price" | transloco }}
                  </p>
                </div>
              </div>
            </div>
          }
        } @else if (isKasko() === false) {
          <div class="mb-4 py-6">
            <div class="mb-2 rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4">
              <p class="mb-1 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite">
                {{ "costCheck.repair" | transloco }}
              </p>
              <ul class="type-body-1 list-none space-y-2 pl-0">
                @for (item of repairList; track item) {
                  <li class="flex items-center gap-3">
                    <cgx-icon class="h-5 w-5" [content]="bulletpointIcon"></cgx-icon>
                    <span class="text-cg-anthracite" [innerHTML]="item.key | transloco: { price: item.value }"></span>
                  </li>
                }
              </ul>
            </div>
            <div class="rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4">
              <p class="mb-1 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite">
                {{ "costCheck.replace" | transloco }}
              </p>
              <ul class="type-body-1 list-none space-y-2 pl-0">
                @for (item of replaceList; track item) {
                  <li class="flex items-center gap-3">
                    <cgx-icon class="h-5 w-5" [content]="bulletpointIcon"></cgx-icon>
                    <span class="text-cg-anthracite" [innerHTML]="item.key | transloco: { price: item.value }"></span>
                  </li>
                }
              </ul>
              <p class="pt-2 text-[13px] leading-5">{{ "costCheck.withoutInsurance.note" | transloco }}</p>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
