import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import {
  CtaComponent,
  HeadlineComponent,
  OpenLinkDirective,
  ParagraphComponent,
  ResponsiveBgDirective
} from "@cg/cgx-ui/core";
import type { CgxCta, CgxHeadline, CgxParagraph, CgxTeaserItem } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-teaser-item",
  templateUrl: "./teaser-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, ParagraphComponent, CtaComponent, ResponsiveBgDirective, OpenLinkDirective]
})
export class TeaserItemComponent {
  public content = input<
    CgxTeaserItem & { cta?: CgxCta; headline?: CgxHeadline; paragraph?: CgxParagraph },
    CgxTeaserItem | null
  >(null, {
    transform: (value: CgxTeaserItem) => ({
      ...value,
      cta: value.showCta
        ? {
            id: "cta",
            link: value.link,
            title: value.link.text,
            iconDirection: "none",
            variation: "secondary",
            ngTemplate: "cgxCta"
          }
        : undefined,
      headline: value.title
        ? {
            title: value.title,
            type: "h4",
            ngTemplate: "cgxHeadline"
          }
        : undefined,
      paragraph: value.subtitle
        ? {
            alignment: "left",
            condensed: false,
            size: "s",
            text: value.subtitle,
            ngTemplate: "cgxParagraph"
          }
        : undefined
    })
  });
}
