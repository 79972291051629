<div class="flex flex-col gap-6 cgxT:gap-8 cgxD:gap-12">
  @if (content().headline) {
    <cgx-headline [content]="content().headline"></cgx-headline>
  }

  <cgx-youtube class="aspect-video cgxT:w-[486px]" [youtubeId]="content().youtubeId"></cgx-youtube>

  @if (content().paragraph) {
    <cgx-paragraph class="block max-w-[701px]" [content]="content().paragraph"></cgx-paragraph>
  }

  @if (content().cta) {
    <cgx-cta class="mt-2 cgxT:mt-0" [content]="content().cta"></cgx-cta>
  }
</div>
