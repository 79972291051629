import { AsyncPipe } from "@angular/common";
import { Component, computed, effect, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { filter, take } from "rxjs/operators";
import { ParagraphComponent } from "@cg/cgx-ui/core";
import { TranslocoService } from "@jsverse/transloco";
import { ConfigFacade } from "@cg/config";
import { CgxParagraph } from "@cg/content-api/typescript-interfaces";
import { CccOpeningHoursConfig, GenericOpeningHours } from "@cg/core/interfaces";
import { OpeningHoursComponent } from "../opening-hours/opening-hours.component";

@Component({
  selector: "cgx-ccc-opening-hours",
  templateUrl: "./ccc-opening-hours.component.html",
  styleUrls: ["./ccc-opening-hours.component.scss"],
  standalone: true,
  imports: [OpeningHoursComponent, ParagraphComponent, AsyncPipe]
})
export class CccOpeningHoursComponent {
  private readonly configFacade = inject(ConfigFacade);
  private readonly translocoService = inject(TranslocoService);

  private cccOpeningHoursConfig = toSignal<CccOpeningHoursConfig | undefined>(this.configFacade.cccOpeningHoursConfig$);

  public expansionTitleParagraph = signal<CgxParagraph>({
    text: "",
    alignment: "left",
    condensed: false,
    size: "s",
    ngTemplate: "cgParagraph"
  });

  public openingHoursList = computed(() => {
    const days = this.cccOpeningHoursConfig().days;
    const currentDayIndex = days.findIndex((openingHours: GenericOpeningHours) => openingHours.currentDay);
    return [...days.slice(currentDayIndex), ...days.slice(0, currentDayIndex)];
  });

  public constructor() {
    const effectRef = effect(
      () => {
        if (this.openingHoursList().length === 0) {
          return;
        }

        this.translocoService
          .selectTranslate(...this.getTitleTranslationProps())
          .pipe(
            filter((text: string) => !!text),
            take(1)
          )
          .subscribe((text: string) => {
            // we only need the first change so destroy effect to prevent endless loop
            effectRef.destroy();

            const content = this.expansionTitleParagraph();
            this.expansionTitleParagraph.set({
              ...content,
              text
            });
          });
      },
      { allowSignalWrites: true }
    );
  }

  private getNextOpenDay(): GenericOpeningHours | undefined {
    return this.openingHoursList().find(({ dayOpen, currentDay }: GenericOpeningHours) => dayOpen && !currentDay);
  }

  private getTitleTranslationProps(): [
    translationKey: string,
    params: { to?: string; from?: string; dayFirstTwoChars?: string }
  ] {
    const currentDate = new Date(Date.now());
    const currentHour = currentDate.getHours();
    const openingHourOfDay = this.openingHoursList()[0];
    const openingHourOfNextDay = this.getNextOpenDay();

    const fromString = openingHourOfDay.from;
    const toString = openingHourOfDay.to;
    const openingHour = fromString ? parseInt(fromString, 10) : 0;
    const closingHour = toString ? parseInt(toString, 10) : 0;
    const isOpen = currentHour >= openingHour && currentHour < closingHour;
    const isOpenSoon = currentHour <= openingHour && currentHour < closingHour;

    const textKeyBegin = "openingHours";

    if (isOpen) {
      return [`${textKeyBegin}.isOpen`, { to: toString }];
    } else if (isOpenSoon) {
      return [`${textKeyBegin}.isOpenSoon`, { from: fromString, to: toString }];
    }

    const isSaturday = openingHourOfDay.day.slice(0, 2) === "Sa";

    if (isSaturday) {
      return [`${textKeyBegin}.openOnMonday`, { from: openingHourOfNextDay.from }];
    } else {
      return [
        `${textKeyBegin}.openOnTomorrow`,
        {
          dayFirstTwoChars: openingHourOfNextDay.day.slice(0, 2).toUpperCase(),
          from: openingHourOfNextDay.from,
          to: openingHourOfNextDay.to
        }
      ];
    }
  }
}
