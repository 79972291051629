import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import {
  CtaComponent,
  HeadlineComponent,
  IconComponent,
  ParagraphComponent,
  ResponsiveBgDirective
} from "@cg/cgx-ui/core";
import { CgxContentBlockList, CgxCta, CgxHeadline, CgxList, CgxParagraph } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-content-block-list",
  templateUrl: "./content-block-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ParagraphComponent,
    HeadlineComponent,
    CtaComponent,
    ParagraphComponent,
    IconComponent,
    ResponsiveBgDirective
  ]
})
export class ContentBlockListComponent {
  public content = input.required<
    CgxContentBlockList & {
      headline: CgxHeadline;
      paragraph: CgxParagraph;
      cta: CgxCta;
      list: CgxList & { paragraphItems: CgxParagraph[] };
    },
    CgxContentBlockList
  >({
    transform: (value: CgxContentBlockList) => ({
      ...value,
      headline: value.title
        ? {
            title: value.title,
            type: "h4",
            ngTemplate: "cgxHeadline"
          }
        : null,
      cta: value.link
        ? {
            id: "cta",
            link: value.link,
            title: value.link.text,
            iconDirection: "none",
            variation: "secondary",
            ngTemplate: "cgxCta"
          }
        : undefined,
      paragraph: value.text
        ? {
            alignment: "left",
            condensed: false,
            size: "l",
            text: value.text,
            ngTemplate: "cgxParagraph"
          }
        : undefined,
      list: {
        ...value.list,
        paragraphItems: value.list.items.map((item: string) => ({
          alignment: "left",
          condensed: false,
          size: "l",
          text: item,
          ngTemplate: "cgxParagraph"
        }))
      },
      ngTemplate: "cgxContentBlockList"
    })
  });

  public generateNumericListParagraph(index: number): CgxParagraph {
    return {
      alignment: "left",
      condensed: false,
      size: "l",
      text: `${index + 1}.`,
      ngTemplate: "cgxParagraph"
    };
  }
}
