<div class="flex w-full flex-col gap-y-2 cgxT:gap-y-4 cgxD:gap-y-6">
  <!-- tablet/desktop -->
  <table class="hidden table-auto border-b-4 border-b-cg-gray-light-70 text-cg-anthracite cgxT:table">
    <thead
      class="[&_th]:border-cg-grey-light-70 bg-cg-gray-light-70 [&_th]:border-r [&_th]:p-4 last:[&_th]:border-none"
    >
      <tr class="font-roboto font-bold">
        <th class="rounded-tl text-left">{{ "cgx.damageTypeTable.header.damageType" | transloco }}</th>
        <th>{{ "cgx.damageTypeTable.header.repair" | transloco }}</th>
        <th class="rounded-tr">{{ "cgx.damageTypeTable.header.replace" | transloco }}</th>
      </tr>
    </thead>
    <tbody class="[&_td]:border-r [&_td]:p-4 last:[&_td]:border-none">
      @for (item of tableContent; track item.text) {
        <tr class="even:bg-cg-gray-light-80">
          <td>{{ item.text | transloco }}</td>

          @if (item.repair) {
            <td>
              <cgx-icon class="mx-auto [--icon-size:24px]" [content]="checkIcon"></cgx-icon>
            </td>
          } @else {
            <td></td>
          }
          @if (item.replace) {
            <td>
              <cgx-icon class="mx-auto [--icon-size:24px]" [content]="checkIcon"></cgx-icon>
            </td>
          } @else {
            <td></td>
          }
        </tr>
      }
    </tbody>
  </table>

  <!-- mobile -->
  <table class="table border-b-4 border-b-cg-gray-light-70 cgxT:hidden">
    @for (contentItem of mobileContent; track contentItem.title) {
      <thead
        class="[&_th]:border-cg-grey-light-70 overflow-hidden bg-cg-gray-light-70 [&_th]:border-r [&_th]:p-4 last:[&_th]:border-none"
      >
        <tr>
          <th [ngClass]="{ 'rounded-t': $first }">{{ contentItem.title | transloco }}</th>
        </tr>
      </thead>
      <tbody class="[&_td]:border-r [&_td]:px-4 [&_td]:py-1.5 last:[&_td]:border-none">
        @for (item of contentItem.items; track item.text) {
          <tr class="h-15 even:bg-cg-gray-light-80">
            <td>{{ item.text | transloco }}</td>
          </tr>
        }
      </tbody>
    }
  </table>
</div>
